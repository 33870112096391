.card-about {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5% 5% 5% 5%;
    transition: 0.3s;
    border: 1px solid rgba(63, 159, 190, 0.50);
}

.card-about:hover {
    -webkit-box-shadow: 0px 0px 20px 6px rgba(63, 159, 190, 0.75);
    -moz-box-shadow: 0px 0px 20px 6px rgba(63, 159, 190, 0.75);
    box-shadow: 0px 0px 20px 6px rgba(63, 159, 190, 0.75);
}

#cardIcon {
    margin: 3%;
    width: 15%;
}

#cardText {
    color: white;
    font-family: Segoe UI;
    font-size: 1.7vw;
}

@media only screen and (max-width: 768px) {
    #cardText {
        font-size: 3.5vw;
    }
}