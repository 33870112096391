canvas {
    background-color: #000022;
}

.landing-hero-page {
    width: 100%;
    height: 100vh;
    padding-left: 5%;
    color: #ffffff;
    font-family: 'Segoe UI';
    display: flex;
    align-items: center;
    padding-top: 3%;
}

.bg-color-hack {
    background-color: #000022;
}

.content {
    width: 100%;
    height: 100%;
}

.mobile-content {
    display: none;
}

.title {
    font-size: 3.5vw;
    text-align: left;
    margin-bottom: 1%;
    font-family: Trebuchet MS;
}

.subtitle {
    font-family: Segoe UI;
    font-size: 1.5vw;
    font-weight: 300;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.neural-net-image {
    background-image: url("../../images/backgroundImg.png");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: 40% 50%;
}

.register {
    transition: all 0.3s ease 0s;
    margin-top: 5%;
    padding: 3%;
    background-color: #04cfda;
    color: white;
    font-size: 2.5vw;
    border-radius: 12px;
    outline: none;
    border: none;
    width: 45%;
    a {
        color: #fff;
        text-decoration: none;
    }
    &:hover {
        width: 50%;
        background-color: #4A8FA1;
        transition: all 0.6s ease 0s;
        cursor: pointer;
    }
}


/* scroll down arrow taken from: https://codepen.io/tr13ze/pen/VjLLgJ */

.scroll-down {
    opacity: 1;
    -webkit-transition: all .5s ease-in 3s;
    transition: all .5s ease-in 3s;
}

.scroll-down {
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -16px;
    display: block;
    width: 40px;
    height: 40px;
    border: 2px solid #FFF;
    background-size: 14px auto;
    border-radius: 50%;
    z-index: 1;
    -webkit-animation: bounce 2s infinite 2s;
    animation: bounce 2s infinite 2s;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    transform: scale(1)
}

.scroll-down:before {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 6px);
    transform: rotate(-45deg);
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    border: 2px solid white;
    border-width: 0px 0 2px 2px;
}

@keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    60% {
        -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}


/*    MEDIA QUERIES     */

//768 px
@media screen and (max-width: 768px) {
    .landing-hero-page {
        padding: 5%;
        text-justify: distribute;
        background-color: none;
    }
    .neural-net-image {
        // width: 100%;
        // height: 35vh;
        // background-size: 50%;
        // background-position: 50% 50%;
        display: none;
    }
    .title {
        font-size: 5vw;
    }
    .subtitle {
        font-size: 4vw;
    }
    .mobile-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .register {
        width: 20%;
        margin-top: 0;
        font-size: 3.5vw;
        &:hover {
            width: 25%;
        }
    }
    .content {
        display: none;
    }
    .particles {
        position: fixed;
        right: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}

@media screen and (max-width: 600px) {
    .neural-net-image {
        width: 130%;
    }
}