@import '../../scss/variables.scss';
.schedule {
    background-color: $primary;
    color: white;
    font-family: 'Segoe UI';
    min-height: 100vh;
    padding: 5%;
    h5 {
        text-align: center;
    }
    .nav {
        border: none;
        font-size: 1.75vw;
        padding-bottom: 0.5%;
    }
    .nav-item {
        cursor: pointer;
    }
    .nav-link {
        color: white;
    }
    .nav-tabs {
        margin: auto;
        width: fit-content;
    }
    .tab-pane {
        width: 50%;
        margin: auto;
        .container {
            background-color: $primary;
        }
    }
    .event {
        border-top: 1px solid white;
        font-size: 1.5vw;
        &:nth-child(1) {
            border-top: 0px solid white;
        }
    }
    .nav-item.active {
        background-color: $secondary;
    }
}

@media screen and (max-width: 1200px) {
    .schedule {
        .nav {
            font-size: 2.5vw;
        }
        .event {
            font-size: 2.0vw;
        }
    }
}

@media screen and (max-width: 768px) {
    .schedule {
        .nav {
            font-size: 3.5vw;
        }
        .event {
            font-size: 3vw;
        }
    }
}

@media screen and (max-width: 500px) {
    .schedule {
        .nav {
            font-size: 4.5vw;
        }
        .event {
            font-size: 4vw;
        }
    }
}