    @import '../../scss/variables.scss';
    .partners-section {
        padding-top: 5rem;
        padding-bottom: 5rem;
        background: rgb(97, 138, 147);
        background: linear-gradient(0deg, $degrade 0%, $primary 100%);
    }
    
    .parters-center,
    .center-logos {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }
    
    .partners-logos-container {
        padding-top: 10px;
        width: 90%;
    }
    
    .center-logos {
        padding-bottom: 20px;
    }
    
    .sponsor-us-btn {
        float: center;
        transition: all 0.3s ease 0s;
        padding: 1%;
        background-color: #6d7879;
        color: white;
        font-size: 1.5vw;
        border-radius: 12px;
        outline: none;
        border: none;
        width: 15%;
        a {
            color: #fff;
            text-decoration: none;
        }
        &:hover {
            width: 12%;
            background-color: #4A8FA1;
            transition: all 0.6s ease 0s;
            cursor: pointer;
        }
    }
    
    @media screen and (max-width: 768px) {
        .sponsor-us-btn {
            font-size: 2.5vw;
            width: 20%;
            &:hover {
                width: 18%;
            }
        }
    }
    
    @media screen and (max-width: 600px) {
        .sponsor-us-btn {
            font-size: 4vw;
            width: 30%;
            &:hover {
                width: 28%;
            }
        }
    }