@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");
@import '../../../scss/variables.scss';

/* Style the accordion section */

$qa-border: 3px solid $secondary;
.accordion__section {
    display: flex;
    flex-direction: column;
    border: $qa-border;
}


/* Style the buttons that are used to open and close the accordion panel */

.accordion {
    background-color: $primary;
    color: white;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
}


/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.accordion:hover,
.active {
    background-color: $primary-lite;
}


/* Style the accordion content title */

.accordion__title {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
}


/* Style the accordion chevron icon */

.accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
    path {
        fill: $secondary;
    }
}


/* Style to rotate icon when state is active */

.rotate {
    transform: rotate(90deg);
}


/* Style the accordion content panel. Note: hidden by default */

.accordion__content {
    background-color: $primary;
    color: white;
    overflow: auto;
    transition: max-height 0.6s ease;
    overflow-y: hidden;
    /* Hide scrollbar for IE, Edge and Firefox */
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    
}


/* Style the accordion content text */

.accordion__text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
}