@import '../../scss/variables.scss';
.faq {
    width: 100%;
    background: $primary;
    padding: 5%;

    select {
        height: 3em;
        margin-bottom: 2.5em;
        border: none;
    }
    // padding-top: 0;
    .faq-col-padding {
        padding-bottom: 20px;
    }
}

.accordion {
    padding: 5px 10px 5px 10px;
}

.accordion-title {
    margin-bottom: 0rem !important;
}