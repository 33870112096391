.about {
    padding-top: 1%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000022;
    font-size: 1.5vw;
}

.about-description-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.about-text {
    color: white;
    font-family: Segoe UI;
    padding: 5%;
}

.brain-image {
    background-image: url("../../images/brainImage.png");
    background-size: 130%;
    background-repeat: no-repeat;
    background-position: center;
    background-position: 40% 50%;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .about {
        font-size: 3.5vw;
    }
}