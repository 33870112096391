.container-heading{
    width: fit-content;
    margin:auto;

    h2{
        font-weight: 300;
        color: white;
        font-size: 40;
        margin-bottom: 0.6em;
    }
}