@import '../../scss/variables.scss';
.speakers {
    background-color: $primary;
    // height: 65vh;
    width: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .speaker-wrapper {
        margin: auto;
        display: flex;
        width: min-content;
        padding-top: 10%;
        padding-bottom: 8px;
    }
    .active {
        background-color: $primary;
    }
    h6 {
        color: white;
        text-align: center;
    }
}