    .nav-container {
        background-color: #000022;
        width: 100%;
        position: fixed;
        padding-bottom: 1%;
        z-index: 2;
        box-shadow: 0;
    }
    
    .scroll {
        box-shadow: 0 2px 2px #04cfda62;
    }
    
    .nav-row {
        max-width: 1140px;
        background-color: #000022;
        margin: 0 auto;
    }
    
    .main-nav {
        float: right;
        list-style: none;
        margin-right: 2%;
    }
    
    .main-nav li {
        display: inline-block;
        margin-left: 40px;
        margin-top: 25px;
    }
    
    .a {
        text-transform: uppercase;
        color: #fff;
        cursor: pointer;
    }
    
    .main-nav li .a:link,
    .main-nav li .a:visited {
        color: #ffffff;
        font-size: 18px;
        border-bottom: 2px solid transparent;
        transition: border-bottom 0.2s;
    }
    
    .main-nav li .a:hover,
    .main-nav li .a:active {
        padding-bottom: 5px;
        border-bottom: 2px solid #04cfda;
        color: #fff;
        text-decoration: none;
    }
    
    .nav-logo {
        height: 40px;
        float: left;
        margin-top: 1.5%;
        &:hover {
            cursor: pointer;
        }
    }
    /*** mobile nav ***/
    
    .mobile-nav-icon {
        float: right;
        margin-top: 30px;
        cursor: pointer;
        color: white;
        font-size: 140%;
        display: none;
        right: 0;
        top: 0;
    }
    
    .navLink {
        font-family: Segoe UI;
        color: white;
        font-size: 25px;
        margin-right: 4%;
        &:hover {
            cursor: pointer;
        }
    }

    .margin-left-image{
        margin-top: 1%;
    }

    .align-center{
        text-align: center;
    }

    .text-image{
        color: white;
        margin-bottom: 0;
        margin-right: 2%;
        padding-top: 2%;
        font-size: x-large;
    }

    @media only screen and (max-width: 1200px) {
        .main-nav {
            margin-right: 30px;
        }
    }
    
    @media only screen and (max-width: 1000px) {
        .main-nav li {
            margin-left: 10px;
        }
    }
    
    @media only screen and (max-width: 767px) {
        .main-nav li {
            margin-left: 40px;
        }
        .main-nav li .a:hover,
        .main-nav li .a:active {
            border: 0;
        }
        .main-nav {
            float: left;
            text-align: left;
            padding-left: 0;
            margin-left: -25px;
            margin-top: 20px;
            display: none;
        }
        .show {
            display: block;
        }
        .main-nav li {
            display: block;
            margin-top: 5px;
        }
        .sticky .main-nav li {
            margin-top: 5px;
        }
        .sticky .main-nav {
            margin-top: 15px;
        }
        .mobile-nav-icon {
            position: absolute;
            display: inline-block;
            margin-right: 10%;
            margin-top: 25px;
        }
        .main-nav li .a:link,
        .main-nav li .a:visited {
            display: block;
            border: 0;
        }
    }